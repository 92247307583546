import React, { useState } from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridMultaCondutor from "components/Grid/GridMultaCondutor";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SemRegistro from "components/Uteis/SemRegistro.js";

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import Confirmacao from 'components/Uteis/Confirmacao.js';

import {
    myFormView
  } from "assets/jss/material-dashboard-react.js";
  
  
  //const tagLabelStyle = makeStyles(myTagLabel);
  const myFormViewStyle = makeStyles(myFormView);
  
  const styles = {
    cardNome: {
      color: "rgba(0,0,0,.62)",
      margin: "0",
      fontSize: "18px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
    cardCnh: {
      color: "rgba(0,0,0,.62)",
      margin: "0",
      fontSize: "18px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "300",
      textAlign: "center",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
    }, 
    cardPontos: {
      color: "rgba(3,169,244,.62)",
      margin: "0",
      fontSize: "30px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "bold",
      textAlign: "center"
    },
    
    myBoxLabel: {
      marginTop: "0px",
      fontWeight: "bold"
    },
    myBoxValue:
    {
      textAlign: "right"
    },
    gridContainer: 
    {paddingTop: "20px", paddingBottom: "30px", height: "10px", borderBottom: " solid 1px #EEEEEE", backgroundColor: ""},
    marginAuto: {
      margin: "0 auto",
    },
    btnAtivarDesativar: {
      backgroundColor: 'transparent',
      color: '#000',
      border: '1px solid #000',
    }
  };
  
  const useStyles = makeStyles(styles);

 function ViewCondutor(props) {
    const [ativo, setAtivo] = useState(false); 
    const [loading, setLoading] = useState(false); 
    const [openConfirmacao, setOpenConfirmacao] = useState(false); 
    const classes = useStyles();
    const classesFormView = myFormViewStyle();

    React.useEffect(() => {          
      setAtivo(props.condutor.Ativo);
    },[props] );


    function ConfirmarDesativarAtivar() {
      setOpenConfirmacao(true);
    }

    function handleCloseConfirmacao() {
      setOpenConfirmacao(false);
    }

    function DesativarAtivar(param) {
      
      setOpenConfirmacao(false);
      setLoading(true);

      var querystring = require('querystring');

      const data = querystring.stringify({Guid : param.condutor.Guid})

      api.post(serverApi.url + serverApi.versao + '/Habilitacao/DesativarAtivar', data).then(response => {
      // handle success
          console.log(response.data.access_token);
          alert('Sucesso!!!!\nOperação realizada com sucesso!');
          setLoading(false);
          setAtivo(!ativo);
      })
      .catch(error => {
      // handle error
          console.error(error.response);
          try{
              switch(error.response.data)
              {                
                  default:
                      alert(error.response.data);
                  break;          
              }
          }
          catch(ex)
          {
              alert('Náo foi possível realizar o recurso!\nTente novamente!');
          }
          setLoading(false);
      })
      .finally( ()=> {
      });
    }

    const somaPontos = (TotalPontosPontuaveisAutuacao, TotalPontosPontuaveisPenalidade, TotalPontosMandatoriosAutuacao, TotalPontosMandatoriosPenalidade) => {
      var total = 0;
      total = TotalPontosPontuaveisAutuacao + TotalPontosPontuaveisPenalidade + TotalPontosMandatoriosAutuacao + TotalPontosMandatoriosPenalidade;
      return total;
    }

  return (
    <>
    <div  style={{display: (props.loading ? 'none' : '')}}>
      <Confirmacao 
        mensagem={'Confirma ' + (ativo ? 'desativar' : 'ativar') + ' o condutor?'}
        handleSim={() => DesativarAtivar(props)}
        openConfirmacao={openConfirmacao} 
        setCloseConfirmacao={handleCloseConfirmacao} 
      /> 
    <div style={{display: (props.condutor.QtdeConsultas <= 0 ? '' : 'none')}}>
        <GridContainer>
        <GridItem lg={3}></GridItem>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <Card>
          <br />
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{fontWeight: 'bold', textAlign: 'center'}}>
                  Fique tranquilo!!!
                  <br />Estamos realizando as buscas junto aos órgãos competentes. Se você não for notificado em 48 horas, entre em contato conosco através do e-mail atendimento@condutorlegal.com informando o número da CNH e CPF cadastrado.
              </GridItem>
            </GridContainer>
          <br />
          </Card>
        </GridItem>
        </GridContainer>
      </div>
      <div style={{display: (props.condutor.ComErro ? '' : 'none')}}>
        <GridContainer>
            <GridItem lg={3}></GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <Card>
              <CardHeader color="danger">
                <div className={classesFormView.cardTitleWhite}>Habilitação com problemas</div>
                <p className={classesFormView.cardCategoryWhite}>Verifique a mensagem abaixo e realize o acerto</p>
              </CardHeader>
              <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{fontWeight: 'bold', textAlign: 'center', width: '100px', height: '50px'}}>
                      {props.condutor.MensagemErro}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
                  <Link to={"../corrigircadastro/" + props.condutor.Id}><Button color="info">Corrigir Habilitação</Button></Link>
                  </GridItem>
                </GridContainer>
              <br />
              </Card>
            </GridItem>
            </GridContainer>
          </div>
      <div style={{paddingTop: "35px"}}>
        <GridContainer>
          <GridItem lg={3}></GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Card>
              <CardBody profile>
                <h6 className={classes.cardNome}>{props.condutor.Nome}</h6>
                <h4 className={classes.cardPontos}>{somaPontos(props.condutor.TotalPontosPontuaveisAutuacao, props.condutor.TotalPontosPontuaveisPenalidade, props.condutor.TotalPontosMandatoriosAutuacao, props.condutor.TotalPontosMandatoriosPenalidade)} Pontos</h4>
              </CardBody>
              <CardBody>
                <div style={{display: (props.condutor.NumeroCnh ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                    <GridItem xs={5} sm={4} md={4} >
                        <div className={classes.myBox}>
                            <div>CNH</div>
                        </div>
                    </GridItem>
                    <GridItem xs={7} sm={8} md={8} >
                        <div className={classes.myBox}>
                            <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.NumeroCnh}</div>
                        </div>
                    </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.Cpf ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                    <GridItem xs={5} sm={4} md={4} >
                        <div className={classes.myBox}>
                            <div>CPF</div>
                        </div>
                    </GridItem>
                    <GridItem xs={7} sm={8} md={8} >
                        <div className={classes.myBox}>
                            <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.Cpf}</div>
                        </div>
                      </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.TotalPontosMandatorios !== '' ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                    <GridItem xs={8} sm={4} md={4} >
                        <div className={classes.myBox}>
                            <div>Pontos Mandatórios</div>
                        </div>
                    </GridItem>
                    <GridItem xs={4} sm={8} md={8} >
                        <div className={classes.myBox}>
                            <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosMandatorios}</div>
                        </div>
                      </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.TotalPontosAnterior !== '' ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                    <GridItem xs={8} sm={4} md={4} >
                        <div className={classes.myBox}>
                            <div>Pontuação anterior</div>
                        </div>
                    </GridItem>
                    <GridItem xs={4} sm={8} md={8} >
                        <div className={classes.myBox}>
                            <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosAnterior}</div>
                        </div>
                      </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.Categoria ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                    <GridItem xs={5} sm={4} md={4} >
                      <div className={classes.myBox}>
                        <div>Categoria</div>
                      </div>
                    </GridItem>
                    <GridItem xs={7} sm={8} md={8} >
                      <div className={classes.myBox}>
                        <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.Categoria}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.Emissao ? '' : 'none')}}>
                  <GridContainer style={myFormView.gridContainer}>
                  <GridItem xs={5} sm={4} md={4} >
                      <div className={classes.myBox}>
                        <div>Emissão</div>
                      </div>
                    </GridItem>
                    <GridItem xs={7} sm={8} md={8} >
                      <div className={classes.myBox}>
                        <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.Emissao}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
                <div style={{display: (props.condutor.Vencimento ? '' : 'none')}}>
                <GridContainer style={myFormView.gridContainer}>
                <GridItem xs={4} sm={4} md={4}>
                      <div className={classes.myBox}>
                          <div>Vencimento</div>
                      </div>
                  </GridItem>
                <GridItem xs={8} sm={8} md={8} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.Vencimento}</div>
                      </div>
                  </GridItem>
                </GridContainer>
                  </div>
                  <div style={{display: (props.condutor.LocalRegistro ? '' : 'none')}}>
                <GridContainer style={myFormView.gridContainer}>
                <GridItem xs={5} sm={4} md={4}>
                      <div className={classes.myBox}>
                          <div>Local Registro</div>
                      </div>
                  </GridItem>
                <GridItem xs={7} sm={8} md={8} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.LocalRegistro}</div>
                      </div>
                  </GridItem>
                  </GridContainer>
                  </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6} classes={{root: classes.marginAuto}}>

          <Card>
              <CardBody>
                <h6 className={classes.cardNome}>{"Pontos (12 Meses)"}</h6>
                <GridContainer style={myFormView.gridContainer} >
                  <GridItem xs={10} sm={10} md={10}>
                      <div className={classes.myBox}>
                          <div>Pontuáveis Autuação</div>
                      </div>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosPontuaveisAutuacao}</div>
                      </div>
                  </GridItem>
                </GridContainer> 
                <GridContainer style={myFormView.gridContainer} >
                  <GridItem xs={10} sm={10} md={10}>
                      <div className={classes.myBox}>
                          <div>Pontuáveis Penalidade</div>
                      </div>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosPontuaveisPenalidade}</div>
                      </div>
                  </GridItem>
                </GridContainer> 
                <GridContainer style={myFormView.gridContainer} >
                  <GridItem xs={10} sm={10} md={10}>
                      <div className={classes.myBox}>
                          <div>Mandatórias Autuação</div>
                      </div>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosMandatoriosAutuacao}</div>
                      </div>
                  </GridItem>
                </GridContainer> 
                <GridContainer style={myFormView.gridContainer} >
                  <GridItem xs={10} sm={10} md={10}>
                      <div className={classes.myBox}>
                          <div>Mandatórias Penalidade</div>
                      </div>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{props.condutor.TotalPontosMandatoriosPenalidade}</div>
                      </div>
                  </GridItem>
                </GridContainer> 
              </CardBody>
            </Card>

          <div style={{display: (props.condutor.PontosPeriodos.length > 0 ? '' : 'none')}}>
            <Card>
              <CardBody>
                {props.condutor.PontosPeriodos.map((pontoPeriodo, index) => {
                  return (
                      <GridContainer style={myFormView.gridContainer} key={index}>
                        <GridItem xs={10} sm={10} md={10}>
                            <div className={classes.myBox}>
                                <div>{pontoPeriodo.Descricao}</div>
                            </div>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2} >
                            <div className={classes.myBox}>
                                <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>{pontoPeriodo.QtdePontos}</div>
                            </div>
                        </GridItem>
                      </GridContainer>          
                  );
                })}
              </CardBody>
            </Card>
            </div>
            

            <div style={{display: (props.condutor.QtdePontos === 1 ? '' : 'none')}}>
            <Card>            
              <CardHeader color="warning">
                <div className={classesFormView.cardTitleWhite}>Ainda nao sei</div>
                <p className={classesFormView.cardCategoryWhite}>em definicao</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                <GridItem xs={5} sm={4} md={4}>
                      <div className={classes.myBox}>
                          <div>Não sei</div>
                      </div>
                  </GridItem>
                <GridItem xs={7} sm={8} md={8} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>Sei lá</div>
                      </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={5} sm={4} md={4}>
                      <div className={classes.myBox}>
                          <div>Não sei</div>
                      </div>
                  </GridItem>
                <GridItem xs={7} sm={8} md={8} >
                      <div className={classes.myBox}>
                          <div className={classes.myBoxValue + ' ' + classes.myBoxLabel}>Sei lá</div>
                      </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </div>
          </GridItem>
        </GridContainer>
    </div>
      <GridContainer>
        <GridItem lg={2}></GridItem>
        <GridItem xs={12} sm={12} md={8} style={{textAlign: 'center'}}>
          <Button onClick={ConfirmarDesativarAtivar} className={classes.btnAtivarDesativar}>{ativo ? 'Desativar' : 'Ativar'}</Button>
        </GridItem>
      </GridContainer>
    </div>
    <div style={{display: (props.condutor.Multas.length > 0 ? '' : 'none')}}>
    <GridContainer>
    
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
                <SemRegistro Exibe={props.condutor.Multas.length <= 0} Texto={'Esta habilitação não possui informações de multas'} />
                <GridMultaCondutor rota={'../../multas/habilitacao/view/'} display={true} multas={props.condutor.Multas} />
            </Card>
        </GridItem>
    </GridContainer>
  </div>
    </>
    );
}
export default ViewCondutor;