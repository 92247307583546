import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Icon from "@material-ui/core/Icon";
import api from 'services/api';
import {serverApi} from 'services/constante.js';

const styles = {
    secondaryTail: {
        backgroundColor: '#4caf50',
    },
    checkedItem: {
      backgroundColor: '#4caf50'
    },
    inativeText: {
      color: '#bdbdbd'
    },
    mensagemTimeLinePrincipal: {
      marginTop: 30,
      textAlign: 'center',
      fontFamily: 'Montserrat, sans-serif',
    },
    mensagemTimeLinePrincipalNome: {
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500
    },
    mensagemTimeLineSecundaria: {
      fontFamily: 'Montserrat, sans-serif',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500
    },
    iconArrowDown: {
      fontSize: '59px'
    },
    boxTimeLine: {
      backgroundColor: '#FFF', 
      marginTop: '15px !important'
    },
    itemTimeLine: {
        color: '#000',
        '&::before': {
          flex: 0,
          content: 'none'
        }
    },
    passo: {
      fontFamily: 'Montserrat, sans-serif',
      color: '#787878',
      marginBottom: 2,
    },
    subTituloPasso: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '17px',
      color: '#000000',
      marginBottom: 2,
      fontWeight: 500,
    },
    descricaoPasso: {
      fontFamily: 'Montserrat, sans-serif',
      color: '#787878'
    },
    btnPrincipal: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 900,
        color: '#FFF',
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'block',
        paddingTop: 30,
        paddingBottom: 30,
        '&:hover': {
            color: '#FFF'
        }
    },
    btnStyle: {
        backgroundColor: '#00acc1',
        width: '50%',
        margin: '0 auto',
        borderRadius: '5px',
        padding: '5px',
    }
};

function MenuDashboard(props) {
    const { classes, children, className, ...other } = props;
    const [nomeUsuario, setNomeUsuario] = useState('');

    useEffect(function() {
      carregaDados();
    }, []);

    function carregaDados() {
      api.get(serverApi.url + serverApi.versao + '/Usuario/Perfil').then(response => {
        if(response.data !== null) {
          let nomeServer = response.data.Nome;
          let nome = nomeServer.split(" ")[0];
          setNomeUsuario(nome);
        }
      })
      .catch(error => {
        console.error(error);
      });
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.boxMensagem} style={{backgroundColor: '#FFF'}}>
                    <p className={classes.mensagemTimeLinePrincipal}>Olá <span className={classes.mensagemTimeLinePrincipalNome}>{nomeUsuario}</span>!</p>
                    <p className={classes.mensagemTimeLineSecundaria}>Consulte agora sua CNH.</p>
                    <p style={{textAlign: 'center'}}><ExpandMoreIcon className={classes.iconArrowDown} /></p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <a className={classes.btnPrincipal} href="/app/condutores">
                        <p className={classes.btnStyle}>
                            <Icon>recent_actors</Icon>
                            <br />
                            Minha CNH
                        </p>
                    </a>
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={6}>
                    <a className={classes.btnPrincipal} href="/app/condutores">
                        <p className={classes.btnStyle}>
                            <Icon>recent_actors</Icon>
                            <br />
                            Minha CNH
                        </p>
                    </a>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <a className={classes.btnPrincipal} href="/app/veiculos">
                        <p className={classes.btnStyle}>
                            <Icon>directions_car_filled</Icon>
                            <br />
                            Meu Veículo
                        </p>
                    </a>
                </GridItem> */}
            </GridContainer>
        </>
    );
}

MenuDashboard.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
  };
  
  export default withStyles(styles)(MenuDashboard);