import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { Link } from '@material-ui/core';
import SnackbarNotification from 'components/Uteis/SnackbarNotification';


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemProdutosExtras: {
    fontWeight: 500,
    padding: 5,
    color: "#FFF",
    backgroundColor: "#000"
  },
  total: {
    fontWeight: 700,
  },
  descontoTitulo: {
    
  },
  desconto: {
    fontWeight: 700,
    color: "green"
  },
  title: {
    marginTop: theme.spacing(2),
  },
  logoPix: {
      textAlign: "right",
        '& svg': {
            width: 50,
            height: 50
        }
  },
  helperTextPE: {
    marginTop: -10
  },
  boxHotmart: {
      border: "5px dotted red",
      padding: 15,
      backgroundColor: "#EEE"
  },
  itemHotmart: {
      padding: "0px 5px",
      backgroundColor: "yellow",
      marginTop: 10
  },
  labelPE: {
    '& .MuiTypography-root': {
        color: '#000'
    }
  },
  iconArrowPE: {
      marginTop: "-9px",
      marginRight: "3px",
      color: "red"
  }
}));

const GreenCheckbox = withStyles({
    root: {
        color: '#000',
        '&$checked': {
          color: '#000',
        },
      },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export default function ReviewPix(props) {
    const classes = useStyles();
    const [checkboxPE, setCheckboxPE] = useState(new Map());
    const [totalVenda, setTotalVenda] = useState(0);
    const [totalCupom, setTotalCupom] = useState({Cupom: "", Valor: 0, Percentual: 0});
    const [snackPush, setSnackPush] = React.useState({ open: false, mensagem: '' });

    useEffect(() => {          
        total_venda();
    },[props.cupom, props.pesSolo, props.stateDataCupom] );

    let total_plano = 0;
    
    function total_venda() {
        props.items.map(function(plano) {
            let periodicidade = 0;
            if (plano.Periodicidade == "A") {
                periodicidade = 12;
            } else if(plano.Periodicidade == "S") {
                periodicidade = 6;
            } else if (plano.Periodicidade == "M") {
                periodicidade = 1;
            }
            total_plano = (total_plano + (parseFloat(plano.Preco) * periodicidade));
        });
        props.pesSolo.map(function(item) {
            if (item.Quantidade) {
                total_plano = total_plano + item.Preco * item.Quantidade;
            } else total_plano = total_plano + item.Preco;
        });

        let percentual;
        if(props.stateDataCupom) {
            //verificar se tem validade
            let cc = props.stateDataCupom;
            if(cc.ValidadeMin != "" || cc.ValidadeMax != "") {
                let dataAtual = new Date();
                let dataMin = new Date(cc.ValidadeMin);
                let dataMax = new Date(cc.ValidadeMax);
                if(dataAtual < dataMin || dataAtual > dataMax) {
                    //cupom inválido
                    setSnackPush({ open: true, mensagem : "Cupom inválido"});
                    setTimeout(() => {
                        setSnackPush({ open: false, mensagem : ''});
                    }, 1000);
                } else {
                    //cupom válido
                    if(cc.PercentualDesconto > 0) {
                        percentual = cc.PercentualDesconto / 100;
                        let valor_percentual = (total_plano * percentual);
                        total_plano = total_plano - valor_percentual;
                        setTotalCupom({Cupom: cc.Nome, Valor: valor_percentual, Percentual: cc.PercentualDesconto});
                        setSnackPush({ open: true, mensagem : "Cupom aplicado"});
                        setTimeout(() => {
                            setSnackPush({ open: false, mensagem : ''});
                        }, 1000);
                    } else if(cc.ValorDesconto > 0) {
                        total_plano = total_plano - cc.ValorDesconto;
                        setTotalCupom({Cupom: cc.Nome, Valor: cc.ValorDesconto, Percentual: 0});
                        setSnackPush({ open: true, mensagem : "Cupom aplicado"});
                        setTimeout(() => {
                            setSnackPush({ open: false, mensagem : ''});
                        }, 1000);
                    }
                }
            } else {
                //cupom válido
                if(cc.PercentualDesconto > 0) {
                    percentual = cc.PercentualDesconto / 100;
                    let valor_percentual = (total_plano * percentual);
                    total_plano = total_plano - valor_percentual;
                    setTotalCupom({Cupom: cc.Nome, Valor: valor_percentual, Percentual: cc.PercentualDesconto});
                    setSnackPush({ open: true, mensagem : "Cupom aplicado"});
                    setTimeout(() => {
                        setSnackPush({ open: false, mensagem : ''});
                    }, 1000);
                } else if(cc.ValorDesconto > 0) {
                    total_plano = total_plano - cc.ValorDesconto;
                    setTotalCupom({Cupom: cc.Nome, Valor: cc.ValorDesconto, Percentual: 0});
                    setSnackPush({ open: true, mensagem : "Cupom aplicado"});
                    setTimeout(() => {
                        setSnackPush({ open: false, mensagem : ''});
                    }, 1000);
                }
            }
        } else {
            setTotalCupom({Cupom: "", Valor: 0, Percentual: 0});
            setSnackPush({ open: true, mensagem : "Cupom inválido"});
            setTimeout(() => {
                setSnackPush({ open: false, mensagem : ''});
            }, 1000);
        }

        setTotalVenda(total_plano)
    }
    //total_venda();

    function imprimeVigencia(vigencia) {
        let vigencia_formatada = "";
        if (vigencia == 'A') {
            vigencia_formatada = "Periodicidade Anual";
        } else if(vigencia == 'S') {
            vigencia_formatada = "Periodicidade Semestral";
        } else if(vigencia == 'M') {
            vigencia_formatada = "Periodicidade Mensal";
        }
        return vigencia_formatada;
    }

    function formataValor(valor) {
        return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    function handleChangeCheckbox(e) {
        const valorPE = e.target.value;
        const item = e.target.name;
        const isChecked = e.target.checked;
        setCheckboxPE(checkboxPE.set(item, isChecked));
        
        if(isChecked) {
            setTotalVenda(totalVenda + parseFloat(valorPE));
        } else {
            setTotalVenda(totalVenda - parseFloat(valorPE));
        }
        props.handleChangeGuidsPE(item)
    }

    return (
        <React.Fragment>
            <SnackbarNotification showAlert={snackPush.open} mensagem={snackPush.mensagem} />
        <Typography variant="h6" gutterBottom>
            Resumo do pedido
        </Typography>
        <List disablePadding>
            {props.items.map((plano, index, array) => (
                <ListItem className={classes.listItem} key={index}>
                    <ListItemText primary={plano.Descricao} secondary={imprimeVigencia(plano.Periodicidade)} />
                    <Typography variant="body2">{`${formataValor(plano.Preco)}/mês`}</Typography>
                </ListItem>
            ))}
            {props.pesSolo.map((item, index, array) => (
                <ListItem className={classes.listItem} key={index}>
                    <ListItemText primary={`${item.Quantidade}x - ${item.Nome}`} secondary={item.Descricao} />
                    <Typography variant="body2">{formataValor(item.Preco)}</Typography>
                </ListItem>
            ))}
            {/* <div className={classes.boxHotmart} style={{ display: (props.pes.length > 0) ? 'block' : 'none'  }}>
                <Typography variant="subtitle1" className={classes.listItemProdutosExtras}>
                    Compre +
                </Typography>
                <div className={classes.itemHotmart}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {props.pes.map((item, index, array) => (
                                <div key={item.Guid}>
                                    <ArrowForwardIcon className={classes.iconArrowPE} />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={checkboxPE.get(item.name)} value={item.ValorProduto} name={item.Guid} onChange={handleChangeCheckbox} />}
                                        label={`${item.NomeProduto} - ${formataValor(item.ValorProduto)}`}
                                        className={classes.labelPE}
                                    />
                                    <FormHelperText className={classes.helperTextPE}>{item.DescricaoProduto}</FormHelperText>
                                </div>
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>
            </div> */}
            {totalCupom.Cupom != "" && totalCupom.Valor > 0 && (
                <ListItem className={classes.listItem}>
                    <ListItemText primary={'Desconto (' + totalCupom.Cupom + ')'} className={classes.descontoTitulo} />
                    <Typography variant="subtitle1" className={classes.desconto}>
                        - {formataValor(totalCupom.Valor)}
                    </Typography>
                    <span>{totalCupom.Percentual > 0 ? ` (${totalCupom.Percentual}%)` : ""}</span>
                </ListItem>
            )}
            <ListItem className={classes.listItem}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" className={classes.total}>
                    {formataValor(totalVenda)}
                </Typography>
            </ListItem>
        </List>
        <Grid container spacing={2}>
            <Grid item container direction="column" xs={12} sm={12}>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Dados de pagamento
            </Typography>
            <Grid container>
                <React.Fragment>
                    <Grid item xs={6}>
                        <Typography gutterBottom>Método de pagamento</Typography>
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.logoPix }}>
                        <svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z"></path><path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z"></path></g></svg>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField id="cupom" label="Cupom" value={props.stateCupom} onChange={e => props.handleCupom(e.target.value)} fullWidth />
                        <Link href="#" color="primary" onClick={async (event) => {
                            event.preventDefault();
                            await props.handleApplyCupom();
                        }}>Aplicar</Link>
                    </Grid>
                </React.Fragment>
            </Grid>
            </Grid>
        </Grid>
        </React.Fragment>
    );
}