import React from "react";
// core components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";

function ViewMultaNaoAcompanhada(props) {
  const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });

  const clickCadastarMulta = (codPedido) => {
    props.props.history.push({
      pathname: `multas-nao-acompanhadas/cadastrar-multa`,
      state: { idPedido: codPedido }
    });
  }

  return (
    <>
      <div style={{ paddingTop: "35px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Cadastrar Multa</TableCell>
                <TableCell align="center">Pedido</TableCell>
                <TableCell align="center">Petição Entregue</TableCell>
                <TableCell align="center">Data Entrega Petição</TableCell>
                <TableCell align="center">Data Compra</TableCell>
                {/* <TableCell align="center">Data da Última Atualização</TableCell> */}
                {/* <TableCell align="center">Download Petição</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.solicitacoes.map((rec, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {!rec.Multa_Id && rec.Entregue == 0 ? (
                        <Button variant="contained" color="primary" onClick={() => clickCadastarMulta(rec.Id)}>Cadastrar Multa</Button>
                      ) : (
                        rec.DataMultaCadastrada ? formatDate(rec.DataMultaCadastrada) : "-"
                      )}
                      
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {rec.TransactionId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {rec.Entregue == 1 ? "Sim" : "Não"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Entregue == 1 && rec.DataEntreguePeticao ? formatDate(rec.DataEntreguePeticao) : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.DataCriacao ? formatDate(rec.DataCriacao) : "-"}
                    </TableCell>
                    {/* <TableCell component="th" scope="row" align="center">
                      {rec.DataUltimaAtualizacao ? formatDate(rec.DataUltimaAtualizacao) : "-"}
                    </TableCell> */}
                    {/* <TableCell component="th" scope="row" align="center">
                      {rec.UrlPeticao}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default ViewMultaNaoAcompanhada;
