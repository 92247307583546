import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';


export default function MySnackbarSuccess(props) {

  return (
    <div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.showAlert}
        onClick={props.clickClose}
        ContentProps={{
            'aria-describedby': 'snackbar-fab-message-id',
            style:{backgroundColor: '#4caf50', textAlign: 'center'}
        }}
        message={<span id="message-id">{props.mensagem}</span>}
        />
    </div>
  );
}
