import React from "react";
// core components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";

function ViewRecurso(props) {
  const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });

  const downloadBlob = (base64, filename, mimetype) => {
    const pdfBlob = base64ToBlob(base64, mimetype);
    const url = URL.createObjectURL(pdfBlob); // Cria um link temporário do Blob
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    //link.setAttribute("download", filename); // Define o nome do arquivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Libera a memória
  };

  const getFileMimeType = (filename) => {
    const mimeTypes = {
      "pdf": "application/pdf",
      "txt": "text/plain",
      "csv": "text/csv",
      "json": "application/json",
      "html": "text/html",
      "xml": "application/xml",
      "jpg": "image/jpeg",
      "jpeg": "image/jpeg",
      "png": "image/png",
      "gif": "image/gif",
      "svg": "image/svg+xml",
      "webp": "image/webp",
      "mp3": "audio/mpeg",
      "wav": "audio/wav",
      "mp4": "video/mp4",
      "avi": "video/x-msvideo",
      "mov": "video/quicktime",
      "zip": "application/zip",
      "rar": "application/x-rar-compressed",
      "7z": "application/x-7z-compressed",
      "tar": "application/x-tar",
      "gz": "application/gzip",
      "doc": "application/msword",
      "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "xls": "application/vnd.ms-excel",
      "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "ppt": "application/vnd.ms-powerpoint",
      "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    };
  
    const extension = filename.split(".").pop().toLowerCase();
    return mimeTypes[extension] || "application/octet-stream"; // Retorna um MIME genérico se não for encontrado
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let i = 0; i < byteCharacters.length; i += 512) {
      const slice = byteCharacters.slice(i, i + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let j = 0; j < slice.length; j++) {
        byteNumbers[j] = slice.charCodeAt(j);
      }
  
      byteArrays.push(new Uint8Array(byteNumbers));
    }
  
    return new Blob(byteArrays, { type: mimeType });
  };

  return (
    <>
      <div style={{ paddingTop: "35px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Auto de Infração</TableCell>
                <TableCell align="center">Fase</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Protocolo</TableCell>
                <TableCell align="center">Placa</TableCell>
                <TableCell align="center">Renavam</TableCell>
                <TableCell align="center">Data Última Consulta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.recursos.map((rec, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {rec.AutoDeInfracao}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.TipoDefesa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Status}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {
                      (rec.Protocolo && rec.FileNameProtocolo) ?
                          <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<CloudDownloadIcon />}
                            onClick={() => downloadBlob(rec.FileProtocolo, rec.FileNameProtocolo, getFileMimeType(rec.FileNameProtocolo))}
                            >
                              {rec.Protocolo}
                            </Button>
                        :
                        (rec.Protocolo) ? rec.Protocolo :
                          "-"  
                      }
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Placa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Renavam}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {formatDate(rec.DataUltimaMovimentacao)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default ViewRecurso;
