import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import PaymentForm from './PaymentForm';
import Review from './Review';
import LoadingGrid from "components/Loading/LoadingGrid.js";
import LoadingPayment from "components/Loading/LoadingPayment";
import SnackbarNotification from 'components/Uteis/SnackbarNotification';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import axios from 'axios';
require('dotenv').config();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [ 'Detalhes de pagamento', 'Resumo do pedido'];

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [mensagemPedido, setMensagemPedido] = useState("");
  const [typeMessage, setTypeMessage] = useState("");
  //ESTADOS FORMULÁRIO
  const [card_name, setCardName] = useState("");
  const [error_card_name, setErrorCardName] = useState(false);
  const [card_cpf, setCardCpf] = useState("");
  const [error_card_cpf, setErrorCardCpf] = useState(false);
  const [card_mes, setCardMes] = useState("");
  const [error_card_mes, setErrorCardMes] = useState(false);
  const [card_ano, setCardAno] = useState("");
  const [error_card_ano, setErrorCardAno] = useState(false);
  const [card_number, setCardNumber] = useState("");
  const [error_card_number, setErrorCardNumber] = useState(false);
  const [card_cvv, setCardCVV] = useState("");
  const [error_card_cvv, setErrorCardCvv] = useState(false);
  const [card_brand, setCardBrand] = useState("");
  const [error_card_brand, setErrorCardBrand] = useState(false);
  const [parcelas, setParcelas] = useState(1);
  const [arrParcelas, setArrParcelas] = useState([]);
  const [cupom, setCupom] = useState("");
  const [dataCupom, setDataCupom] = useState(null);
  //ITEMS
  const [items, setItems] = useState([]);
  const [pe, setPE] = useState([]);// Produtos Extras
  const [guidsPE, setGuidsPE] = useState([]);// Guids Produtos Extras
  const [pesSolo, setPesSolo] = useState([]);// Produtos Extras SEPARADOS

  const [snackPush, setSnackPush] = React.useState({ open: false, mensagem: '' });

  function handleCardName(value) { setCardName(value); setErrorCardName(false); }
  function handleCardCpf(value) { setCardCpf(value); setErrorCardCpf(false); }
  function handleCardMes(value) { setCardMes(value); setErrorCardMes(false); }
  function handleCardAno(value) { setCardAno(value); setErrorCardAno(false); }
  function handleCardNumber(value) { setCardNumber(value); setErrorCardNumber(false); }
  function handleCardCVV(value) { setCardCVV(value); setErrorCardCvv(false); }
  function handleCardBrand(value) { setCardBrand(value); setErrorCardBrand(false); }
  function handleParcelas(value) { setParcelas(value); }
  function handleCupom(value) { setCupom(value); }
  
  useEffect(() => {          
    carregarDados();
  },[props] );

  useEffect(() => {
    calcula_parcelas();
  },[pesSolo] );

  async function carregarDados() {
      setLoadingGrid(true);
      
      let prodExtras = [];
      let id_plano = props.match.params.idplano;
      let id_plano_detalhe = props.match.params.idplanodetalhe;

      if (id_plano != undefined && id_plano_detalhe != undefined) {
        var formdata = new FormData();
        formdata.append('function', 'get_plano_by_id_plano_id_plano_detalhe');
        formdata.append('id_plano', id_plano);
        formdata.append('id_plano_detalhe', id_plano_detalhe);

        await api.post(serverApi.urlPHP + '/plano.php', formdata)
        .then(function (response) {
          
          let plano = response.data.plano;
          setItems([{Descricao: plano.Descricao, Periodicidade: plano.Periodicidade, Preco: plano.Preco}]);

          /* //GOOGLE ANALYTICS
          window.gtag('event', 'select_content', {
            "content_type": "product",
            "items": [
            {
                "id": id_plano + "_" + id_plano_detalhe,
                "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                "brand": "Condutor Legal",
                "category": "Plano",
                "quantity": 1,
                "price": parseFloat(plano.Preco)
            }
          ]}); */
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {   
          setLoadingGrid(false);
          
        });
      } else {

        let guids = "";
        let quant = "";
        if(props.location.state != undefined) {
          guids = props.location.state.guidspesolo;
          quant = props.location.state.guidsquantity;
        }
        
        //let prodExtras = [];
        for (var [key, value] of guids) {
          let guidAtual = key;
          const quantAtual = quant.get(guidAtual);
          await api.get(serverApi.url + `${serverApi.versao}/ProdutoExtra/Resumo/${guidAtual}`)
          .then(function (response) {
            let produto = response.data;
            prodExtras.push({Guid: produto.Guid, Nome: produto.NomeProduto, Descricao: produto.DescricaoProduto, Preco: produto.ValorProduto, Quantidade: quantAtual});
            //ABASTECER ARRAY DE GUIDS
            handleChangeGuidsPE(produto.Guid, quantAtual);
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {   
            setLoadingGrid(false);
          });
        }
        setPesSolo(prodExtras);
      }

      produtosExtras(prodExtras);
  }

  async function produtosExtras(prodExtras) {
    await api.get(serverApi.url + '/api/appUsuario/v1/ProdutoExtra/Todos')
    .then(function (response) {
        let listaExtrasBd = response.data;
        let listaExtrasIguaisTemp = [];
        for (let index = 0; index < listaExtrasBd.length; index++) {
            const element = listaExtrasBd[index];
            for (let i = 0; i < prodExtras.length; i++) {
                const element2 = prodExtras[i];
                if(element.Guid == element2.Guid) {
                    listaExtrasIguaisTemp.push(element);
                } 
            }
        }
        for (let index = 0; index < listaExtrasIguaisTemp.length; index++) {
            const element = listaExtrasIguaisTemp[index];
            listaExtrasBd.splice(listaExtrasBd.indexOf(element), 1);
        }
        setPE(listaExtrasBd);
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(() => {   
        //setLoadingGrid(false);
    });
}

  function getStepContent(step) {
    switch (step) {
      //case 0:
        //return <AddressForm />;
      case 0:
        return <PaymentForm
                  handleCardName={handleCardName}
                  stateCardName={card_name}
                  stateErrorCardName={error_card_name} 
                  handleCardCpf={handleCardCpf}
                  stateCpf={card_cpf}
                  stateErrorCardCpf={error_card_cpf}
                  handleCardMes={handleCardMes}
                  stateMes={card_mes}
                  stateErrorCardMes={error_card_mes}
                  handleCardAno={handleCardAno}
                  stateAno={card_ano}
                  stateErrorCardAno={error_card_ano}
                  handleCardNumber={handleCardNumber}
                  stateCardNumber={card_number}
                  stateErrorCardNumber={error_card_number}
                  handleCardCVV={handleCardCVV}
                  stateCardCVV={card_cvv}
                  stateErrorCardCvv={error_card_cvv}
                  handleCardBrand={handleCardBrand}
                  stateBrand={card_brand}
                  stateErrorCardBrand={error_card_brand}
                  handleParcelas={handleParcelas}
                  stateParcelas={parcelas}
                  stateArrParcelas={arrParcelas}
                  items={items}
                  pesSolo={pesSolo}
                  stateCupom={cupom}
                  handleCupom={handleCupom}
                  handleAppyCupom={calcula_parcelas}
                />;
      case 1:
        return <Review 
                  stateCardBrand={card_brand}
                  stateCardName={card_name}
                  stateCardNumber={card_number}
                  stateCardMes={card_mes}
                  stateCardAno={card_ano}
                  stateCardCpf={card_cpf}
                  items={items}
                  pes={pe} 
                  pesSolo={pesSolo}
                  handleChangeGuidsPE={handleChangeGuidsPE}
                  stateDataCupom={dataCupom}
                />;
      default:
        throw new Error('Unknown step');
    }
  }

  async function calcula_parcelas() {
    let pp = []; 
    let total_plano = 0;
    let periodicidade = 0;
    
    const cupom_bd = await applyCupom();

    if(items.length > 0) {
      items.map(function(plano) {
        if (plano.Periodicidade == "A") {
          periodicidade = 12;
        } else if(plano.Periodicidade == "S") {
          periodicidade = 6;
        } else if (plano.Periodicidade == "M") {
          periodicidade = 1;
        }
        total_plano = (total_plano + (parseFloat(plano.Preco) * periodicidade));
      });
      for (let index = 1; index <= periodicidade; index++) {
        arrParcelas.push(`${index}x de ${formataValor(total_plano/index)}`);      
      }
      //props.handleParcelas(parcelas.length);
    } else {
      pesSolo.map(function(item) {
        if (item.Quantidade) {
          total_plano = total_plano + item.Preco * item.Quantidade;
        } else total_plano = total_plano + item.Preco;
      });

      
      let percentual;
      if(cupom_bd != undefined && cupom_bd.data.response == "success" && cupom_bd.data.cupom) {
        //verificar se tem validade
        let cc = cupom_bd.data.cupom;
        if(cc.ValidadeMin == "" || cc.ValidadeMax == "") {
          let dataAtual = new Date();
          let dataMin = new Date(cc.ValidadeMin);
          let dataMax = new Date(cc.ValidadeMax);
          if(dataAtual < dataMin || dataAtual > dataMax) {
            //cupom inválido
            setSnackPush({ open: true, mensagem : "Cupom inválido"});
            setTimeout(() => {
              setSnackPush({ open: false, mensagem : ''});
            }, 1000);
          } else {
            //cupom válido
            if(cc.PercentualDesconto > 0) {
              percentual = cc.PercentualDesconto / 100;
              total_plano = total_plano - (total_plano * percentual);
            } else if(cc.ValorDesconto > 0) {
              total_plano = total_plano - cc.ValorDesconto;
            }
            setSnackPush({ open: true, mensagem : "Cupom aplicado"});
            setTimeout(() => {
              setSnackPush({ open: false, mensagem : ''});
            }, 1000);
          }
        } else {
          //cupom válido
          if(cc.PercentualDesconto > 0) {
            percentual = cc.PercentualDesconto / 100;
            total_plano = total_plano - (total_plano * percentual);
          } else if(cc.ValorDesconto > 0) {
            total_plano = total_plano - cc.ValorDesconto;
          }
          setSnackPush({ open: true, mensagem : "Cupom aplicado"});
          setTimeout(() => {
            setSnackPush({ open: false, mensagem : ''});
          }, 1000);
        }
      }
      
      if(cupom_bd != undefined && cupom_bd.data.response == "error") {
        setSnackPush({ open: true, mensagem : "Erro no cupom"});
        setTimeout(() => {
          setSnackPush({ open: false, mensagem : ''});
        }, 1000);
      }

      //CASO PARA COMPRA SOMENTE DE PRODUTOS EXTRAS
      
      const division = Math.floor(total_plano / 100);
      let maxIndex = 1;
      switch (true) {
        case division <= 0:
          maxIndex = 1;
          break;
        case division > 12:
          maxIndex = 12;
          break;
        default:
          maxIndex = division;
          break;
      }
      for (let index = 1; index <= maxIndex; index++) {
        pp.push(`${index}x de ${formataValor(total_plano/index)}`);      
      }
      setArrParcelas(pp);
      handleParcelas(pp.length);
    }
  }

  function formataValor(valor) {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } 
  const handleNext = () => {
    if(activeStep == 0) {
      let saida = false;
      if(card_name == "") { setErrorCardName(true); saida = true; }
      if(card_cpf == "") { setErrorCardCpf(true); saida = true; }
      if(card_number == "") { setErrorCardNumber(true); saida = true; }
      if(card_mes == "") { setErrorCardMes(true); saida = true; }
      if(card_ano == "") { setErrorCardAno(true); saida = true; }
      if(card_cvv == "") { setErrorCardCvv(true); saida = true; }
      if(card_brand == "") { setErrorCardBrand(true); saida = true; }
      
      if(saida) {
        return false;
      }
    }

    if(activeStep == 1) {
      handleSubmitPayment();
    }
    setActiveStep(activeStep + 1);
  };

  async function handleSubmitPayment() {
    setLoadingPayment(true);
    let email_user = localStorage.getItem("CLLogin");
    let id_plano = props.match.params.idplano;
    let id_plano_detalhe = props.match.params.idplanodetalhe;
    let guids_produtos_extras = guidsPE;

    var formdata = new FormData();
    formdata.append('email', email_user);
    formdata.append('card_name', card_name);
    //formdata.append('card_mes', card_mes);
    //formdata.append('card_ano', card_ano);
    //formdata.append('card_number', card_number);
    //formdata.append('card_cpf', card_cpf);
    //formdata.append('card_cvv', card_cvv);
    formdata.append('parcelas', parcelas);
    formdata.append('cupom', cupom);
    
    //let publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB"; //sandbox
    let publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu9nDJ/RDTsRkMdFQJ5B3e0BvWa38Wey3NyTtJghvmXlVIJ+LvuDPhPg5DKGQ1N8xAFcstPUkciC01ONxVuD6mzK5ZxusZW8xUAHedYEEa+YRxRhZ5C0f3I6exO0b2xEUZvXyxiBmwmCm4iFsJea6ePKqcn7i4GHyYlbUsyM6E1TjkrZ5Ekn9O8GeJ5mfVxCHPzwNkECl4DN9WkGGPtZ0h3lG4ekDRotte2/+l3+IcVp0ZTcs84OkyN6d0fvxl1KJJidnwqNYlMYWWTFenj8W1kdJtZ1uoeMMlLv31DnXAZuWr3fehpO6wEmnF1SZlpjqv06DX09BPbeucntPqz03IQIDAQAB"; //production
    //criptografar cartão
    const card = window.PagSeguro.encryptCard({
      publicKey: publicKey,
      holder: card_name,
      number: card_number,
      expMonth: card_mes,
      expYear: "20" + card_ano,
      securityCode: card_cvv
    })
    
    if(!card.hasErrors) {
      const encrypted = card.encryptedCard;
      formdata.append('card_token', encrypted);
      
      setMensagemPedido("tudo certo com a excriptação");
      setTypeMessage("success");
      
      let urlCheckout = "gerapedido2.php";
      //VERIFICA SE TEM PLANO ENVOLVIDO NA COMPRA
      if (id_plano != undefined && id_plano_detalhe != undefined) {
        formdata.append('plano', id_plano);
        formdata.append('plano_detalhe', id_plano_detalhe);
        urlCheckout = "gerapedido_plano.php";
      }
      formdata.append('guids_produtos_extras', JSON.stringify(guids_produtos_extras));
      formdata.append('payment_method', "CARTAO");
      //formdata.append('assinatura', "ASSINATURA");
      //formdata.append('idPlanoBp', 1);

      await api.post(serverApi.urlPHP + '/ecommerce/' + urlCheckout, formdata)
        .then(function (response) {

          if(response.data.success) {
            setMensagemPedido(response.data.mensagemExibir);
            setTypeMessage("success");
          }

          if(response.data.error) {
            setMensagemPedido(response.data.error);
            setTypeMessage("error");
          }
        })
        .catch(function (error) {
          setMensagemPedido("Infelizmente ocorreu um erro na geração de sua compra, tente novamente efetuar a compra revendo os dados de pagamento se estão corretos. Caso o erro persistir entre em contato no email contato@condutorlegal.com.br");
          setTypeMessage("error");
          
        })
        .finally(() => {   
          setLoadingPayment(false);
        });
    }
    //console.log("errors", card.errors);
  }

  async function applyCupom() {
    if(cupom == "") return;
    let cupom_return = null;

    var formdata = new FormData();
    formdata.append('function', 'aplica_cupom');
    formdata.append('cupom', cupom);
    formdata.append('produtos', pesSolo);
    
    await api.post(serverApi.urlPHP + '/cupom.php', formdata)
      .then(function (response) {
        setDataCupom(null);
        cupom_return = response;
        if(response.data.response == "success" && response.data.cupom) {
          setDataCupom(response.data.cupom);
        }

        if(response.data.response == "error") {
          setDataCupom(null);
          setCupom("");
        }
    })
    .catch(function (error) {
      
    })
    .finally(() => {   
      //setLoadingPayment(false);
    });
    return cupom_return;
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function handleChangeGuidsPE(guid, quantidade) {
    let listaGuids = guidsPE;
    const index = listaGuids.findIndex(item => item.guid === guid);
    if (index < 0) {
      listaGuids.push({ guid, quantidade });
    } else {
      listaGuids.splice(index, 1);
    }
    setGuidsPE(listaGuids);
}

  return (
    <> 
      <SnackbarNotification showAlert={snackPush.open} mensagem={snackPush.mensagem} />
      <LoadingGrid display={loadingGrid} />
      <LoadingPayment display={loadingPayment} />
      <div style={{display: (!loadingGrid ? '' : 'none')}}>
        <React.Fragment>
          <CssBaseline />
          <main className={classes.layout} id="boxPagamento">
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h4" align="center">
                Checkout
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Obrigado por seu pedido.
                    </Typography>
                    <Alert variant="filled" severity={typeMessage}>
                      {mensagemPedido}
                    </Alert>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                          Voltar
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Pagar' : 'Próximo'}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </main>
        </React.Fragment>
      </div>
    </>
  );
}