import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputMask from 'react-input-mask';
import { Link } from '@material-ui/core';

export default function PaymentForm(props) {

  function anosValidade() {
    let now = new Date
    let anoAtual = now.getFullYear();
    let anos = [];
    for (let index = 1; index <= 10; index++) {
      anos.push(anoAtual + index);
    }
    return anos;
  }
  
  let anos = anosValidade();
  
  /* let parcelas = [];
  async function calcula_parcelas() {
    let total_plano = 0;
    let periodicidade = 0;

    if(props.items.length > 0) {
      props.items.map(function(plano) {
        if (plano.Periodicidade == "A") {
          periodicidade = 12;
        } else if(plano.Periodicidade == "S") {
          periodicidade = 6;
        } else if (plano.Periodicidade == "M") {
          periodicidade = 1;
        }
        total_plano = (total_plano + (parseFloat(plano.Preco) * periodicidade));
      });
      for (let index = 1; index <= periodicidade; index++) {
        parcelas.push(`${index}x de ${formataValor(total_plano/index)}`);      
      }
      //props.handleParcelas(parcelas.length);
    } else {
      //CASO PARA COMPRA SOMENTE DE PRODUTOS EXTRAS
      props.pesSolo.map(function(item) {
        if (item.Quantidade) {
          total_plano = total_plano + item.Preco * item.Quantidade;
        } else total_plano = total_plano + item.Preco;
      });
      const division = Math.floor(total_plano / 100);
      let maxIndex = 1;
      switch (true) {
        case division <= 0:
          maxIndex = 1;
          break;
        case division > 12:
          maxIndex = 12;
          break;
        default:
          maxIndex = division;
          break;
      }
      for (let index = 1; index <= maxIndex; index++) {
        parcelas.push(`${index}x de ${formataValor(total_plano/index)}`);      
      }
      props.handleParcelas(parcelas.length);
    }
  } */

  function formataValor(valor) {
    return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados de pagamento
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField required id="cardName" label="Nome como consta no cartão" value={props.stateCardName} onChange={e => props.handleCardName(e.target.value)} error={props.stateErrorCardName} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputMask mask="999.999.999-99" 
            maskChar=" "
            label="CPF"
            value={props.stateCpf}
            onChange={e => props.handleCardCpf(e.target.value.split(".").join("").replace("-",""))}
            required
            error={props.stateErrorCardCpf}
          >
            {(inputProps) => <TextField {...inputProps} 
              type="text"
              id="cpf"
              fullWidth
            />}
          </InputMask>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
            required 
            type="number" 
            id="cardNumber"
            value={props.stateCardNumber}
            label="Número do cartão"
            onChange={e => props.handleCardNumber(e.target.value)}
            fullWidth
            error={props.stateErrorCardNumber}
          />
        </Grid>
        <Grid item spacing={1} xs={12} sm={6} md={3}>
          <FormControl style={{marginTop: 15, width: '100%'}}>
            <Select
              native
              value={props.stateMes}
              onChange={e => props.handleCardMes(e.target.value)}
              required
              error={props.stateErrorCardMes}
            >
              <option value={''}>Mês</option>
              <option value={'01'}>01</option>
              <option value={'02'}>02</option>
              <option value={'03'}>03</option>
              <option value={'04'}>04</option>
              <option value={'05'}>05</option>
              <option value={'06'}>06</option>
              <option value={'07'}>07</option>
              <option value={'08'}>08</option>
              <option value={'09'}>09</option>
              <option value={'10'}>10</option>
              <option value={'11'}>11</option>
              <option value={'12'}>12</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item spacing={1} xs={12} sm={6} md={3}>
          <FormControl style={{marginTop: 15, width: '100%'}}>
            <Select
                native
                value={props.stateAno}
                onChange={e => props.handleCardAno(e.target.value)}
                required
                error={props.stateErrorCardAno}
            >
              <option value={''}>Ano</option>
              {anos.map((value, index, array) => 
                <option value={value.toString().substr(-2)}>{value}</option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            value={props.stateCardCVV}
            type="number"
            onChange={e => props.handleCardCVV(e.target.value)}
            fullWidth
            error={props.stateErrorCardCvv}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl style={{marginTop: 15, width: '100%'}}>
              <Select
                native
                value={props.stateBrand}
                onChange={e => props.handleCardBrand(e.target.value)}
                required
                error={props.stateErrorCardBrand}
              >
                <option value={''}>Bandeira</option>
                <option value="MASTERCARD">MASTERCARD</option>
                <option value="VISA">VISA</option>
                <option value="AMEX">AMEX</option>
                <option value="MAESTRO">MAESTRO</option>
                <option value="ELO">ELO</option>
                <option value="DINERS">DINERS</option>
                <option value="JCB">JCB</option>
                <option value="DISCOVER">DISCOVER</option>
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl style={{marginTop: 15, width: '100%'}}>
              <Select
                native
                value={props.parcelas}
                onChange={e => props.handleParcelas(e.target.value)}
                required
              >
                {props.stateArrParcelas.map((value, index) => (
                  <option value={(index + 1)} selected={index = 0 ? "selected" : ""}>{value}</option>
                ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField id="cupom" label="Cupom" value={props.stateCupom} onChange={e => props.handleCupom(e.target.value)} fullWidth />
          <Link href="#" color="primary" onClick={async (event) => {
              event.preventDefault();
              await props.handleAppyCupom();
            }}>Aplicar</Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}