import React from 'react';
import { Link } from "react-router-dom";
// @material-ui/core components
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Icon } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'trnsparent',
  },
  titulo: {
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500
  },
  inline: {
    display: 'inline',
    color: '#787878',
    fontFamily: 'Montserrat, sans-serif',
  },
  iconColor: {
    color: "#FFF"
  },
  boxPontosDK: {
    position: 'absolute',
    bottom: '15px',
    left: '2px',
    color: '#737495'
  },
  boxPontosMB: {
    position: 'absolute',
    bottom: '0px',
    left: '5px',
    top: '30px',
    color: '#737495'
  },
  itemList: {
    position: 'relative', 
    backgroundColor: '#f5f5f5',
    marginBottom: '15px',
    borderRadius: '10px',
    marginRight: '5px'
  },
  statusDisplay: {
      width: 10,
      height: 10,
      display: 'inline-block',
      borderRadius: '5px',
      marginRight: '5px'
  },
  boxStatus: {
      marginRight: '7px',
      fontFamily: 'Montserrat, sans-serif',
  },
  textStatusDisplay: {
      color: '#787878'
  },
  boxConteudo: {
      paddingLeft: '5px'
  },
  headerDivider: {
    borderLeft: '1px solid #e2e2e2', 
    borderRight: '1px solid #e2e2e2',
    height: '77px',
    position: 'absolute',
    left: '65px',
    top: '35px' 
  }
}));

 function GridCondutor(props) {
  
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)'); 

  const somaPontos = (TotalPontosPontuaveisAutuacao, TotalPontosPontuaveisPenalidade, TotalPontosMandatoriosAutuacao, TotalPontosMandatoriosPenalidade) => {
    var total = 0;
    total = TotalPontosPontuaveisAutuacao + TotalPontosPontuaveisPenalidade + TotalPontosMandatoriosAutuacao + TotalPontosMandatoriosPenalidade;
    return total;
  }
  
  return (
    <div style={{display: (props.display ? '' : 'none')}}>
    <List className={classes.root}>      
      {props.condutores.map((condutor) => {
        return ( 
          <React.Fragment key={condutor}>
            <Link to={condutor.Guid === null ? 'condutores/cadastrar' : "condutores/view/" + condutor.Guid} >
              <ListItem alignItems="flex-start" className={classes.itemList}>
                <ListItemAvatar>
                  <div className={matches ? classes.boxPontosMB : classes.boxPontosDK}>
                    <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px'}}>{somaPontos(condutor.TotalPontosPontuaveisAutuacao, condutor.TotalPontosPontuaveisPenalidade, condutor.TotalPontosMandatoriosAutuacao, condutor.TotalPontosMandatoriosPenalidade)}</div>
                    <div style={{textAlign: 'center', fontSize: '14px'}}>{somaPontos(condutor.TotalPontosPontuaveisAutuacao, condutor.TotalPontosPontuaveisPenalidade, condutor.TotalPontosMandatoriosAutuacao, condutor.TotalPontosMandatoriosPenalidade) > 0 ? 'Pontos' : 'Ponto'}</div>
                    <p style={{marginBottom: '0px', fontSize: '14px'}}>12 meses</p>
                  </div>
                  {/* <Hidden xsDown>
                    <div style={{position: 'absolute', bottom: '40px',left: '75px', color: '#737495'}}>
                      <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px', color: '#000', fontFamily: 'Montserrat, sans-serif',}}>{condutor.QtdeConsultas}</div>
                      <div style={{textAlign: 'center', fontSize: '12px', color: '#787878', fontFamily: 'Montserrat, sans-serif'}}>{condutor.QtdeConsultas > 0 ? 'consultas' : 'consulta'}</div>
                    </div>
                  </Hidden> */}
                  {/* <div className={matches ? classes.boxPontosMB : classes.boxPontosDK}>
                      <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px', color: '#000', fontFamily: 'Montserrat, sans-serif'}}>{condutor.TotalPontos}</div>
                      <div style={{textAlign: 'center', fontSize: '12px', color: '#787878', fontFamily: 'Montserrat, sans-serif'}}>{condutor.TotalPontos > 0 ? 'pontos' : 'ponto'}</div>
                      <p style={{marginBottom: '0px', fontSize: '12px', lineHeight: '16px', fontFamily: 'Montserrat, sans-serif',}}>12 meses</p>
                    </div>
                    <Hidden xsDown>
                      <div style={{position: 'absolute', bottom: '40px',left: '75px', color: '#737495'}}>
                        <div style={{textAlign: 'center', fontSize: '40px', fontWeight: 'bold', paddingBottom: '5px', color: '#000', fontFamily: 'Montserrat, sans-serif',}}>{condutor.QtdeConsultas}</div>
                        <div style={{textAlign: 'center', fontSize: '12px', color: '#787878', fontFamily: 'Montserrat, sans-serif'}}>{condutor.QtdeConsultas > 0 ? 'consultas' : 'consulta'}</div>
                      </div>
                    </Hidden> */}
                </ListItemAvatar>
                { matches ? (<div className={classes.headerDivider}></div>) : ("") }
                <ListItemText
                  className={classes.boxConteudo}
                  style={{marginLeft: matches ? '0px' : '78px'}}
                  primary={<React.Fragment>
                    <Typography
                        component="span"
                        className={classes.titulo}
                        color="textPrimary"
                      >
                    {(condutor.Nome !== '' ? condutor.Nome : condutor.NumeroCnh)}
                    </Typography>
                    </React.Fragment>}
                  secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            {(condutor.Nome !== '' ? 'CNH: ' + condutor.NumeroCnh : 'CPF: ' + condutor.Cpf)}&nbsp;<br />
                            {(condutor.Nome !== '' ? 'CPF: ' + condutor.Cpf : '')}
                        </Typography>&nbsp;<br />
                        { condutor.Chips.map((chip, key) => {
                            //return (<><Chip icon={chip.Style.icon ? <Icon className={classes.iconColor}>{chip.Style.icon}</Icon> : ""} label={chip.Label} style={{backgroundColor : chip.Style.backgroundColor, color : chip.Style.color}} />&nbsp;</>);
                            if(matches) {
                                return <p key={key} style={{marginBottom: 0}}><span className={classes.boxStatus}><span className={classes.statusDisplay} style={{backgroundColor: chip.Style.backgroundColor}}></span><span className={classes.textStatusDisplay}>{chip.Label}</span></span></p>
                            } else {
                                return <span key={key} className={classes.boxStatus}><span className={classes.statusDisplay} style={{backgroundColor: chip.Style.backgroundColor}}></span><span className={classes.textStatusDisplay}>{chip.Label}</span></span>
                            }
                        }) }
                    </React.Fragment>
                  }
                />
                
              </ListItem>
              </Link>
            </React.Fragment>
          );
        })}
      </List>
    </div>
    );
}
export default GridCondutor;