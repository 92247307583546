import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import LoadingFull from "components/Loading/LoadingFull";
import Icon from "@material-ui/core/Icon";
import CardAvatar from "components/Card/CardAvatar.js";
import Tooltip from '@material-ui/core/Tooltip';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import estados from '../../services/estados.json';
import SnackbarNotification from 'components/Uteis/SnackbarNotification';
import MySnackbarErro from 'components/Uteis/MySnackbarErro';

import './styles.css'
import { TramRounded } from "@material-ui/icons";
 
export default class CondutorCadastrar extends React.Component {
  
  constructor(props) {
    super(props);
    this.interval = null;

    checaLiberacaoMenu(props);

    this.state  = {
      NumeroCnh: '',      
      Cpf: '',
      EmailNotificacao: '',
      UF: {"Uf": "RJ"},
      remuneracao: "0",
      blocking: false,
      snackPush: { open: false, mensagem: ''},
      snackBarError: { open: false, mensagem: ''},
      mensagemLoading: '',
    };  

  } 
  componentDidMount(){}

  iniciarLoading = () => {
    let index = 0;

    const mensagensLoading = [
      "Estamos verificando sua CNH...",
      "Consultando sua situação no sistema...",
      "Aguarde um momento, estamos processando seus dados...",
      "Verificando informações com os órgãos de trânsito...",
      "Quase lá! Só mais um instante...",
      "Segurança em primeiro lugar! Estamos garantindo a integridade dos seus dados...",
      "Estamos quase terminando a consulta...",
      "Carregando informações da sua CNH...",
      "Atualizando os detalhes da sua carteira de motorista...",
      "Finalizando a validação dos seus dados...",
    ];
    
    this.setState({ 
      mensagemLoading: mensagensLoading[index] 
    });

    this.interval = setInterval(() => {
      index = (index + 1) % mensagensLoading.length;
      this.setState({ 
        mensagemLoading: mensagensLoading[index]
      });
    }, 2000); // Alterna as mensagens a cada 2 segundos
  };

  pararLoading = () => {
    clearInterval(this.interval);
    this.setState({ mensagemLoading: "" });
  };

  /* cadastrarCondutor = () => {
    
    if(!this.validateForm() )
    {      
      alert('Todos os campos devem ser preenchidos!');
      return;
    }
    const { NumeroCnh, Cpf, EmailNotificacao, UF, remuneracao } = this.state;

    this.setState({blocking: true});

    var querystring = require('querystring');
    const data = querystring.stringify({
      NumeroCnh,
      Cpf,
      EmailNotificacao,
      UF,
      ExerceAtividadeRemunerada: remuneracao === "1" ? true : false
    });

    api.post(serverApi.url + serverApi.versao + '/Habilitacao/Cadastrar', data).then(response => {
      // handle success
        console.log(response.data.access_token);
        alert('Sucesso!!!!\nO cadastro do condutor foi realizado com sucesso!');

        this.props.history.push('/app/condutores');
    })
    .catch(error => {
      // handle error
      console.error(error.response);
      try{
        switch(error.response.data)
        {
          case 'LIMITE_MAX_ATINGIDO':
              alert('O limite de condutores foi atingido!\nEntre em contato conosco e solicite uma mudança no seu plano!');
          break;
          
          default:
              alert(error.response.data);
          break;          
        }
      }
      catch(ex)
      {
        alert('Náo foi possível realizar o cadastro do condutor!\nTente novamente!');

      }
      this.setState({blocking: false});
    })
    .finally( ()=> {
      //this.setState({blocking: false});
    });

  }; */

  cadastrarCondutor = () => {
    
    if(!this.validateForm() )
    {      
      alert('Todos os campos devem ser preenchidos!');
      return;
    }
    const { NumeroCnh, Cpf, EmailNotificacao, UF, remuneracao } = this.state;

    this.setState({blocking: true});
    this.iniciarLoading();

    let email_user = localStorage.getItem("CLLogin");
    
    var formdata = new FormData();
    formdata.append('function', 'cadastrar_v2');
    formdata.append('Email', email_user);
    formdata.append('NumeroCnh', NumeroCnh);
    formdata.append('Cpf', Cpf);
    formdata.append('EmailNotificacao', EmailNotificacao);
    formdata.append('UF', 2);
    formdata.append('ExerceAtividadeRemunerada', remuneracao === "1" ? true : false);
    formdata.append("CadastrarUsuario", false);
    formdata.append("CadastraLead", true);
    formdata.append("DadosLead[0][MachineCode]", 412983);
    formdata.append("DadosLead[0][EmailSequenceCode]", 1787834);
    formdata.append("DadosLead[0][SequenceLevelCode]", 1);
    formdata.append("DadosLead[0][Tag]", 862561);

    api.post(serverApi.urlPHP + '/habilitacao.php', formdata).then(response => {
      if(response.data.response === "success") {
        this.setState({snackPush: { open: true, mensagem: 'CNH cadastrada com sucesso!'}});
        setTimeout(() => {
          var guid = response.data.guid
          this.props.history.push('/app/condutores/view/' + guid);
        }, 2000);

        /* const data = {
          cnh: NumeroCnh,
          cpf: Cpf,
        };

        api.post(serverApi.urlAPIConsultas + '/api/v1/cnh/rj', data, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(responseConsulta => {
          console.log('responseConsulta', responseConsulta);
        }).catch(errorConsulta => {
          console.error('catch', errorConsulta);
        }).finally(() => {
          this.setState({blocking: false});
        }); */
      } else {
        var message = response.data.message ? response.data.message : 'Ocorreu um erro ao tentar cadastrar habilitação, tente novamente';
        this.setState({snackBarError: { open: true, mensagem: message}});
      }
    })
    .catch(error => {
      alert("Ocorreu um erro ao tentar cadastrar habilitação");
      this.setState({blocking: false});
    })
    .finally( ()=> {
      this.setState({blocking: false});
      this.pararLoading();
    });

  };

  validateForm() {
    return this.state.NumeroCnh.length > 0 && this.state.Cpf.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFocus = event => {
    this.setState({snackBarError: { open: false, mensagem: ''}});
  }

  handleChangeEstado = event => {
    this.setState({
      [event.target.id]: {"Uf": event.target.value}
    });
  }

  handleSubmit = event => {
    event.preventDefault();
  }
  
  render() {
    const { remuneracao } = this.state;
    return (
      <GridContainer>
      <LoadingFull display={this.state.blocking} message={this.state.mensagemLoading} />
      <SnackbarNotification showAlert={this.state.snackPush.open} mensagem={this.state.snackPush.mensagem} />
      <MySnackbarErro IsErro={this.state.snackBarError.open} Mensagem={this.state.snackBarError.mensagem} />
      <div style={{display: (!this.state.blocking ? '' : 'none'), width: '100%'}}>
            <CardAvatar profile style={{marginTop: "10px", width: '70px', textAlign: 'center', paddingTop: '5px'}}>
              <Icon style={{fontSize: "50px"}}>recent_actors</Icon>
            </CardAvatar>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        id="NumeroCnh"
                        label="Número Cnh"
                        margin="dense"
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                    <TextField
                        id="Cpf"
                        label="Cpf"
                        margin="dense"
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        fullWidth
                        inputProps={{ maxLength: 11 }}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                  <GridContainer>
                    <GridItem xs={10} sm={11} md={11}>
                      <TextField
                          id="EmailNotificacao"
                          label="E-mail Notificaçao"
                          margin="dense"
                          onChange={this.handleChange}
                          onFocus={this.handleFocus}
                          helperText=""
                          fullWidth
                        />
                    </GridItem>
                    <GridItem md={1}>
                      <Tooltip title="Se preferir, indique um email para ser copiado nas notificações desta habilitação">
                          <Icon className="icon_info_email">info</Icon>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6} >
                <br />
                  <FormControl style={{marginTop: 26, width: '100%'}}>
                    <Select
                      native
                      id="UF"
                      value={this.state.UF.Uf}
                      onChange={this.handleChangeEstado}
                      onFocus={this.handleFocus}
                      required
                      inputProps={{
                        disabled: true
                      }}
                    >
                      {estados.map((currentValue, index, arr) => 
                        <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
            </GridContainer>
            <GridContainer>
              <br />
            </GridContainer>
            <GridContainer>
              <GridItem md={3}></GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl component="fieldset">
                  <FormLabel>Exerce Atividade Remunerada?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="remuneracao"
                    defaultValue="0"
                    name="remuneracao"
                    onChange={e =>
                      this.setState({ remuneracao: e.target.value })
                    }
                    onFocus={this.handleFocus}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Não"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Sim"
                    />
                  </RadioGroup>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer >
                <br />
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                  <Button color="info" size="md" onClick={this.cadastrarCondutor}>Cadastrar</Button>
                </GridItem>
            </GridContainer>
        </GridItem>
        </div>
      </GridContainer>
    );
  }
}