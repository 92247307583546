import React from 'react';
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {myGrid} from "assets/jss/material-dashboard-react.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: 'transparent',
  },
  titulo: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  inline: {
    display: 'inline'
  },
  temRecurso: {
      color: '#3be93b'
  },
  naoTemRecurso: {
    color: 'red'
  },
  simpleValue: {
      textAlign: 'left',
      marginLeft: 10
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row"
  }
}));

const myGridStyle = makeStyles(myGrid);

 function GridMulta(props) {
  
    // const classesGrid = myGridStyle();
    // const classesTaglabel = myTagLabelStyle();
    const classes = useStyles();
    const classesGrid = myGridStyle();
    const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const defineRouteViewMulta = (tipo, id, guid) => {
      if (tipo === "Veiculo") {
        return "multas/view/" + id;
      } else if (tipo === "Habilitacao") {
        return "../app/multas/habilitacao/view/" + guid;
      }
    };
  return (
    <div
      style={{
        display: (props.display ? '' : 'none'),
        paddingTop: "2.5%"
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Solicitação de Recurso</TableCell> */}
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Auto de Infração</TableCell>
              <Hidden xsDown>
                <TableCell align="center">Pontuação</TableCell>
              </Hidden>
              <TableCell align="center">Data Infração</TableCell>
              <TableCell align="center">Data Recurso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.multas.map((multa, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={classesGrid.center + " " + classesGrid.boxSimpleLine}
              >
                {/* {multa.TemRecurso ? (
                  <TableCell component="th" scope="row">
                    <Link to={props.rota + multa.Id}>
                      <div className={classes.statusContainer}>
                        <CheckCircleIcon
                          className={classes.temRecurso}
                          style={{ paddingRight: 5 }}
                        />
                        <p>Legal, solicitei</p>
                      </div>
                    </Link>
                  </TableCell>
                ) : (
                  <TableCell component="th" scope="row" style={{ color: "black"}}>
                    <Link to={props.rota + multa.Id} style={{ textDecoration: 'none' }}>
                      <div className={classes.statusContainer}>
                        <CheckCircleOutlineIcon
                          className={classes.naoTemRecurso}
                          style={{ paddingRight: 5 }}
                        />
                        <p style={{ color: "red" }}>
                          <strong>Recorrer agora</strong>
                        </p>
                      </div>
                    </Link>
                  </TableCell>
                )} */}
                <TableCell align="center">
                  <Link
                    to={defineRouteViewMulta(multa.Tipo, multa.Id, multa.Guid)}
                    style={{ textDecoration: "none" }}
                  >
                    {`R$ ${multa.ValorSerPago}`}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={defineRouteViewMulta(multa.Tipo, multa.Id, multa.Guid)}
                    style={{ textDecoration: "none" }}
                  >
                    {multa.AutoInfracao}
                  </Link>
                </TableCell>
                <Hidden xsDown>
                  <TableCell align="center">
                    <Link to={defineRouteViewMulta(multa.Tipo, multa.Id, multa.Guid)}>
                      {(() => {
                        let valorMulta = parseFloat(multa.ValorSerPago.replace(/[^0-9.-]+/g,""));
                        if (valorMulta <= 8838) return "3";
                        if (valorMulta > 8838 && valorMulta <= 13016) return "4";
                        if (valorMulta > 13016 && valorMulta <= 19523) return "5";
                        if (valorMulta > 19523) return "7";
                      })()}
                    </Link>
                  </TableCell>
                </Hidden>
                <TableCell align="center">
                  <Link to={defineRouteViewMulta(multa.Tipo, multa.Id, multa.Guid)}>
                    {formatDate(multa.DataInfracao)}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link to={defineRouteViewMulta(multa.Tipo, multa.Id, multa.Guid)}>
                    {multa.TemRecurso ? formatDate(multa.DataRecurso) : "-----"}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default GridMulta;
