import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
  }));

  
function LoadingGrid(props) {
  
    const classes = useStyles();

    return (
      <div style={{position: 'fixed', marginTop:'0px', left: '0px', 'width': '100%', background: 'rgba(120, 120, 120, 0.6)', height: '100%', top: '0px', zIndex: '999998', textAlign: 'center', display: (props.display ? '' : 'none')}}>
        <CircularProgress style={{marginTop: '30%'}} className={classes.progress} />
        {props.message && props.message != '' ? (
          <h3 style={{color: 'white'}}>{props.message}</h3>
          ) : ''}
      </div>
    );
}
export default LoadingGrid;